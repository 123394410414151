/******************************************************************************
 * Footer.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";
import Logo from "./Logo.js";
import {VERSION} from "../version";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = () => {
    const year = new Date().getFullYear()
    return (
    <footer className="bg-light p-3 text-center">
        <Logo/>
        <p>Copyright &copy; 2022&ndash;{year} Cedalion Risk Management<br/>Version {VERSION}</p>
    </footer>
)}
;

export default Footer;

/******************************************************************************
 * sassearch-main.js
 *
 * Copyright © 2023 Cedalion Risk Management
 *
 * See https://github.com/KunalN25/multiple-file-uploads/blob/main/src/App.js
 ******************************************************************************/
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Tab, Tabs} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";

import * as JobXApi from "../../api/jobx.api.js";
import * as JobTypesApi from "../../api/jobTypes.api.js";
import * as JobLogApi from "../../api/jobLogs.api.js";
import * as JobsApi from "../../api/jobs.api.js";
import * as JobApi from "../../api/jobs.api.js";

import Loading from "../../components/Loading.js";
import {SassearchSetupForm} from "./sassearch-setup-form.js";
import {SassearchOutputForm} from "./sassearch-output-form.js";
import {consoleError} from "../../misc/misc.js";

import {HTTP} from "../../constants/http.js";
import {BLANK_DISPLAY_AFTER_MS} from "../../constants/misc.js";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SasSearchMain = () => {
    const {user} = useAuth0();

    let thisUserId = undefined;
    if (user) {
        thisUserId = user.sub;
    }

    const [setupData, setSetupData] = useState(null);  // 'setup' data
    const [key, setKey] = useState("setup");            // For tabs
    const [message, setMessage] = useState("");        // Warning/error messages
    const [outputData, setOutputData] = useState();               // Execution output
    const [conferences, setConferences] = useState([]); // Selected conferences
    const [executeEnabled, setExecuteEnabled] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();
    const thisJobId = location.state.jobId;

    const isExecuteEnabled = (setupData) => {
        return (setupData &&
            (setupData.title.length > 0
                || setupData.author.length > 0
                || setupData.keywords.length > 0));
    };

    // blank messages after BLANK_DISPLAY_AFTER_MS
    useEffect(() => {
        setInterval(() => setMessage(""), BLANK_DISPLAY_AFTER_MS);
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const jobData = await JobsApi.getJobs(
                    {
                        jobId: thisJobId
                    });
                const jobXData = await JobXApi.getJobX(
                    {
                        jobId: thisJobId
                    });
                const jobTypeData = await JobTypesApi.getJobTypes(
                    {
                        jobTypeId: jobData.data[0].jobTypeId
                    });
                const jobTypeName = jobTypeData.data[0].name;

                const mySetupData = {
                    ...jobData.data[0],
                    ...jobXData.data.data[0],
                    yearFrom   : jobXData.data.sasData.yearMin,
                    yearTo     : jobXData.data.sasData.yearMax,
                    jobTypeName: jobTypeName,
                    sasData    : jobXData.data.sasData
                };
                setSetupData(mySetupData);

                let confList = [];
                jobXData.data.data[0].conferences.forEach(c => {
                    confList.push({value: c, label: c});
                });
                setConferences(confList);

                setExecuteEnabled(isExecuteEnabled(mySetupData));

                const myOutputData =
                    {
                        data         : jobData.data[0],
                        searchResults: jobXData.data.data[0].sasPapers
                    };
                // Initial output page:
                setOutputData(myOutputData);
            } catch (e) {
                consoleError(e); // ERROR!!
                setMessage(`Error creating job: ${e.message}`);
            }
        };
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickUpdateJobdata = async (data) => {
        try {
            await JobsApi.updateJob(thisJobId, data);

            // Save parmConferences as simple array, not label/value pairs
            let confList = [];
            data.conferences.forEach(c => {
                confList.push(c.value);
            });
            const data1 = {...data, conferences: confList};

            const result = await JobXApi.updateJobX(thisJobId, data1);
            if (HTTP.OK_200 === result.status) {
                setMessage("Criteria updated.");
            } else {
                setMessage("ERROR: " + result.message);
            }
        } catch (e) {
            setMessage("ERROR: " + e.message);
        }
    };

    const onClickDeleteJob = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure you want to delete this job?")) {
            return;
        }
        try {
            await JobXApi.deleteJobX(thisJobId);
            await JobsApi.deleteJob(thisJobId);
            navigate("/jobsList");
        } catch (e) {
            setMessage(`ERROR: ${e.message}`);
        }
    };

    const onClickExecute = async (e) => {
        e.preventDefault();
        setMessage("");

        const executeParms = {
            jobId      : setupData.jobId,
            jobTypeId  : setupData.jobTypeId,
            jobName    : setupData.jobName,
            userId     : setupData.userId,
            title      : setupData.title,
            author     : setupData.author,
            keywords   : setupData.keywords,
            yearFrom   : setupData.yearFrom,
            yearTo     : setupData.yearTo,
            conferences: setupData.conferences
        };
        setKey("output");    // switch to 'output' tab
        setIsExecuting(true);
        const execResults = await JobsApi.executeJob(thisJobId, executeParms);
        setIsExecuting(false);

        if (HTTP.OK_200 !== execResults.status) {
            setMessage(execResults.message);
            execResults.data = {
                stdout       : "",
                stderr       : "",
                status       : 0,
                searchResults: []
            };
            return;
        }

        const outputResults = {
            data         : {
                lastExecStatus: execResults.status,
                lastExecDate  : execResults.data.execDate,
                stderr        : execResults.data.execStderr
            },
            searchResults: execResults.data.execData,
        };
        setOutputData(outputResults);

        // Update Job
        const updateJobResponse = await JobApi.updateJob(thisJobId,
            {
                lastExecDate  : execResults.data.execDate,
                lastExecStatus: execResults.status,
                lastStderr    : execResults.data.execStderr
            });
        if (HTTP.OK_200 !== updateJobResponse.status) {
            const msg = `ERROR: ${updateJobResponse.message}`;
            consoleError(msg);
            setMessage(msg);
        }

        // Update JobX
        const updateJobXResponse = await JobXApi.updateJobX(thisJobId,
            {
                stderr   : execResults.data.execStderr,
                sasPapers: execResults.data.execData
            });
        if (HTTP.OK_200 !== updateJobXResponse.status) {
            const msg = `ERROR: ${updateJobXResponse.message}`;
            consoleError(msg);
            setMessage(msg);
        }

        // Update JobLog
        const createJobLogResults = await JobLogApi.createJobLog({
            jobId          : thisJobId,
            userId         : thisUserId,
            lastExecuted   : execResults.data.lastExecuted,
            executionStatus: execResults.data.executionStatus
        });
        if (HTTP.CREATED_201 !== createJobLogResults.status) {
            const msg = `ERROR: ${createJobLogResults.message}`;
            consoleError(msg);
            setMessage(msg);
        }
        setMessage("Search complete!");
    };

    return setupData ? (
        <div className="col-md-12 container">
            <h1>{setupData.jobTypeName}</h1>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {/*- Setup Tab -----------------------------*/}
                <Tab eventKey="setup" title="Setup">
                    <SassearchSetupForm
                        setupData={setupData}
                        setSetupData={setSetupData}
                        outputData={outputData}
                        message={message}

                        onClickUpdateJobdata={onClickUpdateJobdata}
                        onClickDeleteJob={onClickDeleteJob}
                        onClickExecute={onClickExecute}

                        conferences={conferences}
                        setConferences={setConferences}
                        executeEnabled={executeEnabled}
                        setExecuteEnabled={setExecuteEnabled}
                        isExecuteEnabled={isExecuteEnabled}
                    />
                </Tab>

                {/*- Output Tab ----------------------------*/}
                <Tab eventKey="output" title="Output">
                    <SassearchOutputForm
                        setupData={setupData}
                        outputData={outputData}
                        message={message}
                        executing={isExecuting}
                    />
                </Tab>
            </Tabs>
        </div>
    ) : <Loading/>;
};

export default SasSearchMain;

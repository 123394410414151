/******************************************************************************
 * api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import {consoleError} from "../misc/misc.js";
import {HTTP} from "../constants/http.js";
import {security} from "../auth0/security.js";

import _ from "lodash";

const API =
    {
        DELETE: "DELETE",
        GET   : "GET",
        PATCH : "PATCH",
        POST  : "POST"
    };

/**
 *
 * @param method
 * @param url
 * @param bodyQuery
 * @returns {Promise<{message, status: number}|{data, status}|{status: number}>}
 */
const api = async (method, url, bodyQuery = {}) => {
    // console.log("api", method, url, bodyQuery)
    const token = await security.getAccessTokenFunc()();
    let serverUrl = process.env.REACT_APP_SERVER_URL + url;

    let options = {
        method : `${method}`,
        headers: {
            Authorization : `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };

    switch (method) {
        case API.GET:
            if (!_.isEmpty(bodyQuery)) {
                const urlBodyQuery = new URLSearchParams(bodyQuery).toString();
                if (urlBodyQuery) {
                    //console.log("api", method, url, bodyQuery, urlBodyQuery)
                    serverUrl += `?${urlBodyQuery}`;
                }
            }
            break;
        case API.POST:
        case API.PATCH:
        case API.DELETE:
            if (bodyQuery) {
                options.body = JSON.stringify(bodyQuery);
            }
            break;
        default:
            throw new Error(`{method} is not a valid HTTP method.`);
    }

    let responseData;
    try {
        const response = await fetch(serverUrl, options);

        switch (method) {
            case API.GET:
            case API.POST:
            case API.PATCH:
                responseData = await response.json();
                return {
                    status: responseData.status,
                    data  : responseData.data
                };
            case API.DELETE:
                responseData = await response;
                return {
                    status: responseData.status,
                };
            default:
                throw new Error(`Invalid method ${method} in api call.`)
        }
    } catch (e) {
        consoleError(e.message);
        return {status: HTTP.INTERNAL_ERROR_500, message: e.message};
    }
};

export {
    api,
    API
};
/******************************************************************************
 * dfd-constants.js
 *
 * Copyright © 2023 Cedalion Risk Management
 *
 ******************************************************************************/

import React from "react";
import { ArrowRightSquare } from "react-bootstrap-icons";

import Loading from "../../components/Loading.js";
import JobHeader from "../../components/JobHeader.js";
import SuperTable from "../../components/SuperTable.js";

/**
 *
 * @type {[{Header: string, accessor: string, disableSortBy: boolean, Cell: (function({cell: {value: *}, row: {original: *}}): *)},{Header: string, accessor: string},{Header: string, accessor: string},{Header: string, accessor: string},{Header: string, accessor: string, disableSortBy: boolean},null]}
 */
const superColumns = [
    {
        Header       : "PDF",
        accessor     : "url",
        Cell         : ({ cell: { value }, row: { original } }) =>
            <a href={original.url} target="_blank"
               rel="noreferrer"><ArrowRightSquare/></a>,
        disableSortBy: true
    },
    {
        Header  : "Conference",
        accessor: "conference",
    },
    {
        Header  : "Year",
        accessor: "year",
    },
    {
        Header  : "RScore",
        accessor: "rscore",
    },
    {
        Header       : "Authors",
        accessor     : "author",
        disableSortBy: true,
    },
    {
        Header  : "Title",
        accessor: "title",
    },
];

/**
 *
 * @param setupData
 * @param outputData
 * @param message
 * @param executing
 * @returns {JSX.Element}
 * @constructor
 */
export function SassearchOutputForm(
    {
        setupData,
        outputData,
        message,
        executing
    }) {

    if (executing || !outputData) {
        return <Loading/>;
    }

    // massage data into format SuperTable needs
    let sasPapers = [];
    outputData.searchResults.forEach(row => {
        const thisAuthor = row.Authors.join(", ");
        sasPapers.push({
            url       : row.rURL,
            conference: row.cConf,
            year      : row.cYear,
            rscore    : row.rScore,
            author    : thisAuthor,
            title     : row.rTitle
        });
    });

    return (
        <div>
            <h1>Output</h1>
            <JobHeader setupData={setupData} outputData={outputData}/>
            <hr/>
            <div>
                <h2>Search Criteria</h2>
                <table>
                    <tbody>
                    {setupData.conferences.length > 0 && (
                        <tr>
                            <td><b>Conferences:</b>&nbsp;</td>
                            <td>
                                {setupData.conferences.map(conf => (
                                    <span key={conf.value}>{conf.value}&nbsp;</span>
                                ))}
                            </td>
                        </tr>
                    )
                    }
                    <tr>
                        <td><b>Years:&nbsp;</b></td>
                        <td>{setupData.yearFrom} to {setupData.yearTo}</td>
                    </tr>
                    {setupData.title.length > 0 && (
                        <tr>
                            <td><b>Title:&nbsp;</b></td>
                            <td>{setupData.title}</td>
                        </tr>
                    )}
                    {setupData.author.length > 0 && (
                        <tr>
                            <td><b>Author:&nbsp;</b></td>
                            <td>{setupData.author}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <h2>Search Results</h2>
            <div><SuperTable data={sasPapers} columns={superColumns}/>
            </div>

            <h2>{message}</h2>
        </div>
    );
}

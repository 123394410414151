/******************************************************************************
 * JobsListForm.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"


import SuperTable from "../components/SuperTable.js";
import {JobTypesSelect} from "../components/JobTypesSelect.js";
import {UsersSelect} from "../components/UsersSelect.js";
import {BEG_OF_TIME, END_OF_TIME} from "../constants/misc";

/**
 *
 * @param onClickAdd
 * @param onClickEdit
 * @param data
 * @param setData
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
export function JobLogsListForm({
                                    userArray,
                                    filter,
                                    setFilter,
                                    onClickApplyFilter,
                                    onClickView,
                                    data,
                                    message
                                }) {


    const superColumns = [

        {
            Header  : "EDIT",
            accessor: "edit",
            Cell    :
                ({cell: {value}, row: {original}}) => <button
                    className="btn btn-primary btn-sm"
                    name={original.jobLogId}
                    key={original.jobLogId}
                    onClick={onClickView}>View</button>,

            disableSortBy: true
        },
        {
            Header  : "Job Name",
            accessor: "jobName",
        },
        {
            Header  : "Job Type",
            accessor: "jobTypeName",
        },
        {
            Header  : "User",
            accessor: "userEmail",
        },
        {
            Header  : "Exec Date",
            accessor: "execDate",
            Cell    :
                ({cell: {value}, row: {original}}) =>
                    <span>{original.execDateHuman}</span>
        },
        {
            Header  : "Exec Status",
            accessor: "execStatus",
        },
        {
            Header  : "Deleted?",
            accessor: "deletedStr"
        }
    ];

    if (0 === data) {
        message = "No job logs.";
    }

    const onChangeJobType = (e) => {
        if (e.target.value) {
            filter.jobTypeId = e.target.value;
        } else {
            delete filter.jobTypeId;
        }
        setFilter(filter);
    }

    const onChangeUser = (e) => {
        if (e.target.value) {
            filter.userId = e.target.value;
        } else {
            delete filter.userId;
        }
        setFilter(filter);
    }

    const onChangeStartDate = (date) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            startDate: date || prevFilter.startDate // Maintain user-selected date
        }));
    }

    const onChangeEndDate = (date) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            endDate: date || prevFilter.endDate // Maintain user-selected date
        }));
    }

    const onResetStartDate = () => {
        setFilter(prevFilter => ({
            ...prevFilter,
            startDate: BEG_OF_TIME
        }));
    }

    const onResetEndDate = () => {
        setFilter(prevFilter => ({
            ...prevFilter,
            endDate: END_OF_TIME
        }));
    }

    let userOptions = [{
        key  : '',
        value: 'Select a User'
    }]
    for (const [key, value] of Object.entries(userArray)) {
        userOptions.push({
            key  : key,
            value: `${value.nickname} (${value.email})`

        })
    }

    return (
        <div className="col-md-12 container">
            <form>
                <h2>Job Log</h2>
                <h3>Filter:</h3>
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td>Job Type:</td>
                            <td>
                                <JobTypesSelect
                                    label="jobTypeId"
                                    name="Job Type"
                                    onChange={onChangeJobType}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>User:</td>
                            <td>
                                <UsersSelect
                                    label="userId"
                                    name="User"
                                    options={userOptions}
                                    onChange={onChangeUser}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>From:</td>
                            <td>
                                <DatePicker
                                    selected={filter.startDate}
                                    onChange={onChangeStartDate}
                                />
                                &nbsp;
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={onResetStartDate}>Reset
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>To:</td>
                            <td>
                                <DatePicker
                                    selected={filter.endDate}
                                    onChange={onChangeEndDate}
                                />
                                &nbsp;
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={onResetEndDate}>Reset
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div style={{paddingTop: "1em"}}>
                        <button
                            type='button'
                            className="btn btn-primary"
                            onClick={onClickApplyFilter}
                        >
                            Apply Filter
                        </button>
                    </div>
                </div>
                <hr/>
                <SuperTable data={data} columns={superColumns}/>
            </form>
            <h2>{message}</h2>
        </div>
    );
}

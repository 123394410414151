/******************************************************************************
 * jobLog.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { api, API } from "./api.js";

/**
 *
 * @param execData
 * @returns {Promise<{data, status}>}
 */
const createJobLog = async (execData) => {
    return api( API.POST, `/api/v1/joblogs`, execData);
};

/**
 *
 * @param query
 * @returns {Promise<{data: *, status: *}|{status: *}|{message: *, status: number}|undefined>}
 */
const getJobLogs = async (query = null) => {
    return api( API.GET, '/api/v1/joblogs', query);
};

export {
    createJobLog,
    getJobLogs,
};
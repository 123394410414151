/******************************************************************************
 * jobs.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { api, API } from "./api.js";
import { security } from "../auth0/security.js";

/**
 *
 * @param userId
 * @param jobTypeId
 * @returns {Promise<{error, status}|{data, status}>}
 */
const createJob = async (userId, jobTypeId) => {
    const date = new Date();
    const d = date.toLocaleDateString();
    const t = date.toLocaleTimeString();
    const jobName = "New Job: " + d + " " + t;

    const body = {
        userId   : userId,
        jobTypeId: jobTypeId,
        jobName: jobName
    };
    return api( API.POST, `/api/v1/jobs`, body);
};

/**
 *
 * @param query
 * @returns {Promise<{error, status}|{data, status}>}
 */
const getJobs = async (query) => {
    return api( API.GET, "/api/v1/jobs", query);
};

/**
 *
 * @param jobId
 * @param data

 * @returns {Promise<any>}
 */
const updateJob = async (jobId, data) => {
    return api( API.PATCH, `/api/v1/jobs/${jobId}`, data);
};

/**
 *
 * @param jobId
 * @returns {Promise<{status: number}>}
 */
const deleteJob = async (jobId) => {
    return api( API.DELETE, `/api/v1/jobs/${jobId}`);
};

/**
 *
 * @param jobId
 * @param setupData
 * @returns {Promise<*|{ioSuccess: boolean, message}>}
 */
const executeJob = async (jobId, setupData) => {
    const token = await security.getAccessTokenFunc()();
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const myData = {
        jobId    : jobId,
        setupData: setupData
    };
    let responseData;
    try {
        const response = await fetch(
            `${serverUrl}/api/v1/jobs/execute`,
            {
                method : API.POST,
                headers: {
                    Authorization : `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body   : JSON.stringify(myData)
            }
        );
        responseData = await response.json();
        return responseData;
    } catch (error) {
        return { ioSuccess: false, message: error.message };
    }
};

export {
    createJob,
    getJobs,
    updateJob,
    deleteJob,
    executeJob,
};
/******************************************************************************
 * jobLogView.js
 *
 * Copyright © 2023 Cedalion Risk Management
 *
 * See https://github.com/KunalN25/multiple-file-uploads/blob/main/src/App.js
 ******************************************************************************/

import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import * as JobLogsApi from "../api/jobLogs.api.js";
import * as JobsApi from "../api/jobs.api.js";
import * as UserApi from "../api/user.api.js";

import {HTTP} from "../constants/http.js";
import Loading from "../components/Loading.js";
import {StatusToText} from "../components/StatusToText.js"

const JobLogView = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [jobLogData, setJobLogData] = useState(null)
    const [message, setMessage] = useState('')

    const thisJobLogId = location.state.jobLogId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                //const jobLogsData = await JobLogsApi.getJobLogs(`_id=${thisJobLogId}`);
                const jobLogsData = await JobLogsApi.getJobLogs(
                    {
                        _id: thisJobLogId
                    });
                if (HTTP.OK_200 !== jobLogsData.status) {
                    throw Error("Problem retrieving Job Log data!")
                }
                const jobsData = await JobsApi.getJobs(
                    {
                        jobId  : jobLogsData.data[0].jobId,
                        deleted: "all"
                    })
                if (HTTP.OK_200 !== jobsData.status) {
                    throw Error("Problem retrieving Jobs data!")
                }
                const userData = await UserApi.getUserProfile(jobLogsData.data[0].userId)
                if (HTTP.OK_200 !== userData.status) {
                    throw Error("Problem retrieving User data!")
                }
                setJobLogData({
                    jobLogsData: jobLogsData.data[0],
                    jobsData   : jobsData.data[0],
                    userData   : userData.data
                })
            } catch (err) {
                setMessage(err)
            }
        };
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickBack = () => {
        navigate(-1)
    }

    //const execStatusMsg = (jobLogData && jobLogData.jobLogsData.execStatus && HTTP.OK_200 === jobLogData.jobLogsData.execStatus) ?
    //    getDefaultMessage(jobLogData.jobLogsData.execStatus) : " ";
    //const execStatusMsg = (jobLogData && jobLogData.jobLogsData.execStatus) ? getDefaultMessage(jobLogData.jobLogsData.execStatus) : '';

    return jobLogData ? (
        <div>
            <h1>Job Log Entry</h1>
            <h2>Job Log</h2>
            <table>
                <tbody>
                <tr>
                    <td>Execution Date</td>
                    <td>{jobLogData.jobLogsData.execDate}</td>
                </tr>
                <tr>
                    <td>Execution Status</td>
                    <td><StatusToText status={jobLogData.jobLogsData.execStatus}/></td>
                </tr>
                </tbody>
            </table>
            <h2>User</h2>
            <table>
                <tbody>
                <tr>
                    <td>User Name</td>
                    <td>{jobLogData.userData.given_name} {jobLogData.last_name}</td>
                </tr>
                <tr>
                    <td>Company</td>
                    <td>{jobLogData.userData.company}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{jobLogData.userData.email}</td>
                </tr>
                </tbody>
            </table>
            <h2>Job</h2>
            <table>
                <tbody>
                <tr>
                    <td>Job Name</td>
                    <td>{jobLogData.jobsData.jobName}</td>
                </tr>
                <tr>
                    <td>Create Date</td>
                    <td>{jobLogData.jobsData.createDate}</td>
                </tr>
                {/*
                <tr>
                    <td>Execute Date</td>
                    <td>{jobLogData.jobLogsData.execDate ?? ""}</td>
                </tr>
                <tr>
                    <td>Execute Status</td>
                    <td>{execStatusMsg}</td>
                </tr>
*/}
                <tr>
                    <td>Deleted?</td>
                    <td>{jobLogData.jobsData.deleted ? "Yes" : "No"}</td>
                </tr>
                </tbody>
            </table>
            <p>&nbsp;</p>
            <p>
                <button className="btn btn-primary btn-sm" onClick={onClickBack}>Back</button>
            </p>
        </div>
    ) : <Loading/>
}

export default JobLogView;
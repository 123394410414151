/******************************************************************************
 * StatusToText.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

import {HTTP} from "../constants/http.js";

/**
 *
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
export const StatusToText = ({status}) => {
    let msg;
    switch (status) {
        case HTTP.OK_200:
            msg = "OK";
            break;
        case undefined:
            msg = "Not Yet Executed";
            break;
        default:
            msg = "Fail";
            break;
    }

    return (
        <>
            {msg}
        </>
    )
};

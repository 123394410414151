/******************************************************************************
 * JobsLogs.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import * as JobLogsApi from "../api/jobLogs.api.js";
import * as JobTypesApi from "../api/jobTypes.api.js";
import * as JobsApi from "../api/jobs.api.js";
import * as UserApi from "../api/user.api.js";
import {BEG_OF_TIME, END_OF_TIME} from "../constants/misc";

import {JobLogsListForm} from "./JobLogsListForm.js";
import {dateToHuman} from "../misc/misc.js";
import Loading from "../components/Loading.js";
import {getDefaultMessage, HTTP} from "../constants/http.js";


/**
 * * @param jobId
 * @param jobArray      State Job array
 * @param setJobArray   State setter
 * @returns {Promise<*>}
 */
const jobLookup = async (jobId, jobArray, setJobArray) => {
    const lookup = jobArray[jobId];
    if (lookup) {
        return lookup;
    }

    const jobData = await JobsApi.getJobs(
        {
            jobId  : jobId,
            deleted: "all"
        });
    if (HTTP.OK_200 === jobData.status && 0 < jobData.data.length && jobData.data[0].jobName) {
        const jobTypeData = await JobTypesApi.getJobTypes(
            {
                jobTypeId: jobData.data[0].jobTypeId
            }
        );
        jobArray[jobId] = {
            jobName    : jobData.data[0].jobName,
            userId     : jobData.data[0].userId,
            deleted    : jobData.data[0].deleted,
            jobTypeId  : jobData.data[0].jobTypeId,
            jobTypeName: jobTypeData.data[0].name,
        };
    } else {
        jobArray[jobId] = {
            jobName    : jobId,
            jobTypeId  : "",
            jobTypeName: "",
            userId     : "",
            deleted    : false
        };
    }
    setJobArray(jobArray);
    return jobArray[jobId];
};

/**
 *
 * @type {{}}
 */
const userLookup = async (userId, userArray, setUserArray) => {
    const lookup = userArray[userId];
    if (lookup) {
        return lookup;
    }
    const userData = await UserApi.getUserProfile(userId);
    if (HTTP.OK_200 === userData.status) {
        userArray[userId] = {
            email   : userData.data.email,
            nickname: userData.data.nickname
        };
    } else {
        userArray[userId] = {
            email   : userId,
            nickname: ''
        };
    }

    setUserArray(userArray);
    return userArray[userId];
};

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const JobLogsList = () => {
        const [data, setData] = useState([]);
        const [filter, setFilter] = useState({
            startDate: BEG_OF_TIME,
            endDate  : END_OF_TIME

        });
        const [message, setMessage] = useState("");
        const [jobArray, setJobArray] = useState([]);
        const [userArray, setUserArray] = useState([]);


        let navigate = useNavigate();

        const fetchData = async () => {
            let joblogData = await JobLogsApi.getJobLogs({_sort: 'execDate', _limit: 1})
            const joblogMinDate = new Date(joblogData.data[0].execDate);
            // if(joblogMinDate > filter.startDate) {
            //     setFilter({...filter, 'startDate': joblogMinDate})
            // }
            joblogData = await JobLogsApi.getJobLogs({_sort: '-execDate', _limit: 1})
            const joblogMaxDate = new Date(joblogData.data[0].execDate);
            // if(joblogMaxDate < filter.endDate) {
            //     setFilter({...filter, 'endDate': joblogMaxDate})
            // }
            setFilter(prevFilter => ({
                ...prevFilter,
                startDate: prevFilter.startDate === BEG_OF_TIME ? joblogMinDate : prevFilter.startDate,
                endDate  : prevFilter.endDate === END_OF_TIME ? joblogMaxDate : prevFilter.endDate
            }));
            let dataFilter = {
                extended: true
            }
            if (filter.jobTypeId) {
                dataFilter.jobTypeId = filter.jobTypeId;
            }
            if (filter.userId) {
                dataFilter.userId = filter.userId;
            }
            if (filter.startDate !== END_OF_TIME) {
                let workDateStart = filter.startDate
                workDateStart.setHours(0)
                workDateStart.setMinutes(0);
                workDateStart.setSeconds(0);
                const execDateStart = workDateStart.toISOString()
                dataFilter = {
                    ...dataFilter,
                    "execDate$GTE": execDateStart,
                }
            }
            if (filter.endDate !== BEG_OF_TIME) {
                let workDateEnd = filter.endDate;
                workDateEnd.setHours(23)
                workDateEnd.setMinutes(59)
                workDateEnd.setSeconds(59)
                const execDateEnd = workDateEnd.toISOString()
                dataFilter = {
                    ...dataFilter,
                    "execDate$LTE": execDateEnd
                }
            }

            const jobLogs = await JobLogsApi.getJobLogs(dataFilter);

            const results = [];
            // let startDate = END_OF_TIME
            // let endDate = BEG_OF_TIME
            for (const jobLog of jobLogs.data) {
                const jobLookupData = await jobLookup(jobLog.jobId, jobArray, setJobArray);
                const userLookupData = await userLookup(jobLog.userId, userArray, setUserArray);
                const execStatusMsg = (jobLog.execStatus) ? getDefaultMessage(jobLog.execStatus) : '';

                const newRow =
                    {
                        jobLogId     : jobLog._id,
                        execDate     : jobLog.execDate,
                        execDateHuman: dateToHuman(jobLog.execDate),
                        execStatus   : execStatusMsg,
                        deletedStr   : jobLookupData.deleted ? "Yes" : "No",
                        userEmail    : userLookupData.email,
                        ...jobLookupData,
                    }
                results.push(newRow);

                // const thisDate = new Date(jobLog.execDate)
                // if (thisDate <= startDate) {
                //     startDate = thisDate;
                // }
                // if (endDate <= thisDate) {
                //     endDate = thisDate;
                // }
            }
            setData(results)
            //  setFilter({
            //     ...filter,
            //     startDate: startDate < joblogMinDate ? joblogMinDate : startDate,
            //     endDate  : endDate > joblogMaxDate ? joblogMaxDate : endDate
            // })
            // setFilter({...filter})
        };

        useEffect(() => {
            fetchData().then();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const onClickViewRow = async (event) => {
            event.preventDefault();
            const jobLogId = event.target.name; // Before getAccessTokenSilently() clobbers it

            const pushMe = `/joblogview`;
            navigate(pushMe, {
                state: {
                    jobLogId: jobLogId
                }
            });
        };

        const onClickApplyFilter = (e) => {
            //console.log(e);
            //const x = filter;
            fetchData().then();
        }

        return data ?
            <JobLogsListForm
                userArray={userArray}
                filter={filter}
                setFilter={setFilter}
                onClickApplyFilter={onClickApplyFilter}
                onClickView={onClickViewRow}
                data={data}
                message={message}
            /> :
            <Loading/>;
    }
;
export default JobLogsList;

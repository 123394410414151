/******************************************************************************
 * NavBarCrm.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { useAuth0 } from "@auth0/auth0-react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import LogoAdmin from "../components/LogoAdmin.js";
import LoginButton from "../components/LoginButton.js";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function NavBarCrm() {

    return (
        <Navbar bg="dark" variant={"dark"} expand="lg">
            <Container>
                <Navbar.Brand href="/home"><LogoAdmin/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
{/*
                        <Nav.Link as={Link} to={"/features"}>Features</Nav.Link>
                        <Nav.Link as={Link} to={"/contact"}>Contact</Nav.Link>
*/}
                        <Nav.Link as={Link} to={"/joblogs"}>Jobs Logs</Nav.Link>
                        <AuthButton/>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function AuthButton() {
    const { isAuthenticated, logout } = useAuth0();

    return isAuthenticated ? (
        <NavDropdown title="My Account" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to={"/profile"}>
                Profile
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }>
                Logout
            </NavDropdown.Item>
        </NavDropdown>
    ) : (
        <LoginButton/>
    );
}

/******************************************************************************
 * misc.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

const BLANK_DISPLAY_AFTER_MS = 20000;

const BEG_OF_TIME = new Date(1900, 0, 1, 0, 0, 0)
const END_OF_TIME = new Date(2100, 0, 1, 23, 59, 59)


export {
    BLANK_DISPLAY_AFTER_MS,
    BEG_OF_TIME,
    END_OF_TIME
};
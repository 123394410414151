/******************************************************************************
 * sassearch-setup.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";

import ErrorMsg from "../../components/ErrorMsg.js";
import JobHeader from "../../components/JobHeader.js";

export function SassearchSetupForm({
                                       /*
                                                           setupData={setupData}
                                                           outputData={outputData}
                                                           message={message}
                                                           
                                                           onClickUpdateJobdata={onClickUpdateJobdata}
                                                           onClickDeleteJob={onClickDeleteJob}
                                                           onClickExecute={onClickExecute}
                                                           
                                                           conferences={conferences}
                                                           setConferences={setConferences}
                                                           executeEnabled={executeEnabled}
                                                           setExecuteEnabled={setExecuteEnabled}
                                                           isExecuteEnabled={isExecuteEnabled}
                                                          
                                        */
                                       setupData,
                                       setSetupData,
                                       outputData,
                                       message,

                                       onClickUpdateJobdata,
                                       onClickDeleteJob,
                                       onClickExecute,

                                       conferences,
                                       setConferences,
                                       executeEnabled,
                                       setExecuteEnabled,
                                       isExecuteEnabled,
                                   }) {
    const {
        register, handleSubmit,
        formState: { errors }
    } = useForm(
        {
            defaultValues: { ...setupData, ...outputData }
        });


    // Update all the search parameters
    const onSubmit = (data) => {
        const myData = {
            jobName    : data.jobName,
            title      : data.title,
            author     : data.author,
            yearFrom   : parseInt(data.yearFrom),
            yearTo     : parseInt(data.yearTo),
            conferences: conferences
        };
        onClickUpdateJobdata(myData);
    };

    const onExecute = (data) => {
        onSubmit(setupData); // save the parms
        onClickExecute(data);
    };

    const onChangeJobName = (e) => {
        setupData.jobName = e.target.value;
        setSetupData(setupData);
    };

    const onChangeTitle = (e) => {
        setupData.title = e.target.value;
        setSetupData(setupData);
        setExecuteEnabled(isExecuteEnabled(setupData));
    };
    const onChangeAuthor = (e) => {
        setupData.author = e.target.value;
        setSetupData(setupData);
        setExecuteEnabled(isExecuteEnabled(setupData));
    };

    const onChangeKeywords = (e) => {
        setupData.keywords = e.target.value;
        setSetupData(setupData);
        setExecuteEnabled(isExecuteEnabled(setupData));
    };

    const onChangeConferences = (e) => {
        setConferences(e);
        setupData.conferences = e;
        setSetupData(setupData);
    };

    const onChangeYearFrom = (e) => {
        setupData.yearFrom = e.target.value;
        setSetupData(setupData);
    };

    const onChangeYearTo = (e) => {
        setupData.yearTo = e.target.value;
        setSetupData(setupData);
    };

    const MultiSelect = ({ sasData /*, conferenceData */ }) => {
        let options = [];
        sasData.conferences.forEach((row) => {
            const option = { value: row, label: row };
            options.push(option);
        });
        /*
                const options = [
                    {value: 'One', label: 'one'},
                    {value: 'Two', label: 'two'},
                    {value: 'Three', label: 'three'},
                    {value: 'Four', label: 'four'},
                ]
        */
        return (
            <Select
                options={options}
                onChange={onChangeConferences}
                value={conferences}
                isMulti
            />
        );
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1>Setup</h1>
                <JobHeader setupData={setupData} outputData={outputData}/>

                <div><b>Job Name:</b>&nbsp;<ErrorMsg message={errors.jobName?.message}/></div>
                <div><input {...register("jobName", { required: "Job Name is required!" })}
                            name="jobName"
                            placeholder="Job Name"
                            onChange={onChangeJobName}
                /></div>
                <br/>

                <h2>Search Criteria:</h2>
                <p><i>Enter at least a Title, Author, or Keyword</i></p>

                <b>Title:</b><br/>
                <input {...register("title",)}
                       name="title"
                       placeholder="Your title"
                       onChange={onChangeTitle}
                />
                <br/>

                <b>Author:</b><br/>
                <input {...register("author",)}
                       name="author"
                       placeholder="Your author"
                       onChange={onChangeAuthor}
                />&nbsp;(Enter both first and last name)
                <br/>

                <b>Keywords:</b><br/>
                <input {...register("keywords",)}
                       name="keywords"
                       placeholder="Your keywords (separated by spaces)"
                       onChange={onChangeKeywords}
                       size="40"
                />
                <br/>
                <p>&nbsp;</p>
                <b>Conferences:</b><br/>
                <MultiSelect
                    sasData={setupData.sasData}
                />

                <b>Year Range:</b><br/>
                <input {...register("yearFrom",)}
                       name="yearFrom"
                       type="number"
                       placeholder="Year From"
                       min={setupData.sasData.yearMin}
                       max={setupData.sasData.yearMax}
                       onChange={onChangeYearFrom}
                />
                &nbsp;to&nbsp;
                <input {...register("yearTo",)}
                       name="yearTo"
                       type="number"
                       placeholder="Year To"
                       min={setupData.sasData.yearMin}
                       max={setupData.sasData.yearMax}
                       onChange={onChangeYearTo}
                />
                <br/>


                <hr/>
                <p>
                    <button
                        className="btn btn-primary btn-sm"
                    >
                        Update Search Criteria
                    </button>
                </p>
                <p>
                    <button className="btn btn-success btn-sm"
                            disabled={!executeEnabled}
                            onClick={onExecute}>
                        Execute Search
                    </button>
                    <button className="btn btn-danger btn-sm"
                            style={{ float: "right" }}
                            onClick={onClickDeleteJob}
                    >
                        Delete Job
                    </button>
                </p>
            </form>
            <h2>{message}</h2>
        </div>
    );
}


/******************************************************************************
 * UsersSelect.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

//import * as JobTypesApi from "../api/jobTypes.api.js";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function UsersSelect(props) {
    // const [options, setOptions] = useState([]);
    //
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const jobTypes = await JobTypesApi.getJobTypes();
    //         if (jobTypes.status !== HTTP.OK_200) {
    //             throw new Error(jobTypes.message);
    //         }
    //         const results = [];
    //         jobTypes.data.forEach((value) => {
    //             results.push(
    //                 {
    //                     key  : value.name,
    //                     value: value.jobTypeId
    //                 }
    //             );
    //         });
    //         setOptions([{key: "Select a Job Type", value: ""},
    //             ...results
    //         ]);
    //     };
    //     fetchData().then();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const {label, name, options, ...rest} = props;

    return (
        <select id={name} name={name} {...rest}>
            {options.map((option) => {
                return (
                    <option key={option.key} value={option.key}>
                        {option.value}
                    </option>
                );
            })}
        </select>
    );
}

/*
                return (
                    <option key={option.value} value={option.value}>
                        {option.key}
                    </option>
                );

 */
/******************************************************************************
 * ping.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import {API} from "./api.js";
import {HTTP} from "../constants/http.js";

/**
 * Is the sesrver alive at all? (Returns 200 "OK")
 * @param criteria
 * @returns {Promise<{message, status: number}|{data, status}>}
 */
const ping = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const completeUrl = `${serverUrl}/ping`;
    try {
        let options = {
            method : API.GET,
            headers: {
                "Content-Type": "application/json"
            }
        };

        const response = await fetch(completeUrl, options);
        if (HTTP.OK_200 !== response.status) {
            console.error(`Network error: ${completeUrl}, Message: ${response.message}`);
        }
        return response.json();
    } catch (err) {
        console.error(`Network error: ${completeUrl}, Message: ${err.message}`);
        return {
            status : HTTP.BAD_REQUEST_400,
            message: err.message
        }
    }
}

export {ping};
/******************************************************************************
 * user.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { api, API } from "./api.js";

/**
 *
 * @param userId
 * @returns {Promise<{data, status}>}
 */
const getUserProfile = async (userId) => {
    return api( API.GET, `/api/v1/users/profile/${userId}`);
};

/**
 *
 * @param userId
 * @param myData
 * @returns {Promise<{statusText: *, status}>}
 */
const setUserProfile = async (userId, myData) => {
    const body = {
        nickname    : `${myData.nickname.trim()}`,
        given_name  : `${myData.given_name.trim()}`,
        family_name : `${myData.family_name.trim()}`,
        phone_number: `${myData.phone_number.trim()}`,
        company     : `${myData.company.trim()}`,
        address1    : `${myData.address1.trim()}`,
        address2    : `${myData.address2.trim()}`,
        city        : `${myData.city.trim()}`,
        usState     : `${myData.usState.trim()}`,
        zip         : `${myData.zip.trim()}`,
    };

    return api( API.PATCH, `/api/v1/users/profile/${userId}`, body);
};

/**
 *
 * @param userEmail
 * @returns {Promise<{statusText: *, status}>}
 */
const changePassword = async (userEmail) => {
    const body = {
        email: userEmail,
    };
    return api( API.POST, "/api/v1/users/cpe", body);
};

const getUsers = async (userId, query = null) => {
    return api( API.GET, `/api/v1/users/profile/`, query);
};

export {
    getUserProfile,
    setUserProfile,
    changePassword,
    getUsers
};